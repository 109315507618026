import React from "react"
import { graphql } from "gatsby"
import { Container, Box } from "@theme-ui/components"
import Layout from "../layouts/Layout"
import { getBlogPath } from "../utils/path"
import NewsList from "./newsList"
import BlogTitle from "../components/BlogTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "../layouts/Hero/Page"
import RichContentStructuredText from "../components/RichContentStructuredText"
import TitleAndBody from "../components/TitleAndBody"
import CustomImage from "../components/Image"
import EventsCollection from "../components/EventsCollection"
import NewsCollection from "../components/NewsCollection"
import HighlightedEvent from "../components/HighlightedEvent"

const Blog = ({ data: { page, news, events, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  // console.log(newsCategories)
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getBlogPath(locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.body && (
        <Container
          sx={{
            maxWidth: "758px",
            h1: {
              textAlign: ["center", "unset"],
              textTransform: "lowercase",
              fontSize: [8, 9, 11], //"4.5rem",
              fontFamily: "heading",
              lineHeight: "heading",
              fontWeight: "heading",
              color: "text",
              mb: [2, 2, 5],
              mt: [4, 4, 5],
              em: {
                "-webkit-text-stroke": "1px #181818",
                color: "#181818",
                fontStyle: "normal",
                fontFamily: "body",
                fontWeight: "400",
              },
            },
            h2: {
              color: "text",
              margin: "inherit",
              mt: [4, 8],
              fontSize: [4, 8],
              lineHeight: "1.167",
              em: {
                fontStyle: "normal",
                color: "primary",
              },
            },
          }}
        >
          <RichContentStructuredText text={page.body} />
        </Container>
      )}
      {page.content.map(block =>
        block && block.model && block.model.apiKey ? (
          <Box as="section" key={block.id}>
            {block.model.apiKey === "title_and_body" && (
              <TitleAndBody block={block} theme={"light"} />
            )}
            {block.model.apiKey === "image" && <CustomImage block={block} />}
            {block.model.apiKey === "highlighted_event" && (events.nodes.length > 0 || block.event ) && (
              <HighlightedEvent
                event={events.nodes.length > 0 ? events.nodes[0] : block.event}
              />
            )}
            {block.model.apiKey === "events_collection" &&
              events &&
              events.nodes.length > 0 && (
                <EventsCollection events={events.nodes} block={block} />
              )}
            {block.model.apiKey === "news_collection" &&
              news &&
              news.nodes.length > 0 && (
                <NewsCollection news={news.nodes} block={block} />
              )}
          </Box>
        ) : (
          ""
        )
      )}
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query BlogQuery($id: String!, $locale: String!) {
    page: datoCmsBlogPage(id: { eq: $id }) {
      ...BlogDetails
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      body {
        value
      }
      content {
        ... on DatoCmsHighlightedEvent {
          event {
            ... on DatoCmsEvent {
              ...EventDetails
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEventsCollection {
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsNewsCollection {
          title
          model {
            apiKey
          }
        }
      }
    }
    events: allDatoCmsEvent(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
    ) {
      nodes {
        ...EventDetails
        ...EventAllSlugLocales
        ...EventMeta
      }
    }
    news: allDatoCmsNews(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
    ) {
      nodes {
        ...NewsDetails
        ...NewsAllSlugLocales
        ...NewsMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment BlogDetails on DatoCmsBlogPage {
    id
    locale
    title
    model {
      apiKey
    }
  }
`
