import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Text } from "@theme-ui/components"

const Image = ({ block }) => {
  const {image} = block
  return (
    <Box sx={{position:"relative"}}>
      <Box
        sx={{
          ".gatsby-image-wrapper": {
            width: "100%",
          },
        }}
      >
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Box>
      {image.title && (
        <Box>
          <Text variant="caption">{image.title}</Text>
        </Box>
      )}
    </Box>
  )
}

export default Image
