import React, { useEffect, useState } from "react"
import { Box, Grid, Text, Heading, Container, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../RichContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import LongBlueArrow from "../../assets/images/arrow_blu.inline.svg"

const TitleAndBody = ({ block, theme = "dark" }) => {
  const { title, body, link } = block.content

  const sentences = title.split(". ")
  const [styledString, setStyledString] = useState("")

  useEffect(() => {
    // Check if there are sentences in the input string
    if (sentences.length > 0) {
      // Style the first sentence with <strong> tag
      sentences[0] = `<strong>${sentences[0]}</strong>`
      // Join the sentences back into a single string
      setStyledString(sentences.join(". "))
    } else {
      console.log("Input string is empty or doesn't contain sentences.")
    }
  }, [title])

  return (
    <Box
      sx={{
        py: [4, 9, 9],
        backgroundColor: theme === "dark" ? "dark" : "transparent",
      }}
    >
      <Container sx={{ py: [0, 0, 0] }}>
        <Box sx={{}}>
          <Heading
            as="h2"
            variant="h2"
            sx={{
              color: theme === "dark" ? "light" : "dark",
              textTransform: "lowercase",
            }}
          >
            <Box
              sx={{
                lineHeight: "1",
                strong: {
                  "-webkit-text-stroke":
                    theme === "dark" ? "1px #FFFFFF" : "0px #181818",
                  color: theme === "dark" ? "transparent" : "text",
                  fontWeight: "normal",
                },
              }}
              dangerouslySetInnerHTML={{ __html: styledString }}
            />
          </Heading>
        </Box>
      </Container>
      <Container sx={{ py: [0, 0], px: [0, 4] }}>
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: theme === "dark" ? "light" : "text",
            mb: [0, 0],
          }}
        ></Box>
      </Container>
      <Container sx={{ my: [0, 0] }}>
        <Grid columns={[1, 1, "1fr"]} gap={[0]}>
          <Box
            sx={{
              p: {
                color: theme === "dark" ? "light" : "text",
                fontSize: [3, 4],
              },
            }}
          >
            <RichContentStructuredText text={body} />
          </Box>
          {link && (
            <MagicLink
              item={link}
              sx={{
                textDecoration: "none",
                textTransform: "uppercase",
              }}
            >
              <Flex sx={{ mt: [3] }}>
                <Box
                  sx={{
                    mr: [2],
                    fontWeight: "bold",
                    fontSize: [2],
                    color: theme === "dark" ? "light" : "dark",
                  }}
                >
                  {link.anchor}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    svg: {
                      svg: {
                        fill: "text",
                        stroke: "text",
                      },
                    },
                  }}
                >
                  <LongBlueArrow />
                </Box>
              </Flex>
            </MagicLink>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default TitleAndBody
