import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Container, Text, Heading } from "@theme-ui/components"
import { InboundLink, OutboundLink } from "../../utils/link"
import { getNewsPath } from "../../utils/path"
import { LightOrDark} from "../../utils/lightOrDark"

const HighlightedEvent = ({ event = undefined }) => {
  const textColor = LightOrDark(event.heroImage.colors[0].rgb) ==="dark" ? "light" : "dark"

  return (
    <Box sx={{ position: "relative" }}>
      <Container sx={{px:[0,4], pb:[4,8]}}>
        <InboundLink to={getNewsPath(event, event.locale)}>
          <Box
            sx={{
              position: "relative",
              ".gatsby-image-wrapper": {
                width: "100%",
                maxHeight: "800px",
                minHeight:"355px",
                height: "auto",
              },
            }}
          >
            <GatsbyImage image={event.heroImage.gatsbyImageData} alt="" />
            <Box
              sx={{
                position: ["relative","relative","absolute","absolute"],
                bottom: [0],
                left: [0],
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                px: [4,5],
                pt: [4,5],
                pb: [4,5],
                flexDirection: "column",
                backgroundColor: event.heroImage.colors[0].hex,
                textAlign: ["left","left","center","center"],
              }}
            >
              <Box sx={{width:"100%"}}>
                <Text
                  sx={{
                    fontSize: [2, 2],
                    color: textColor,
                  }}
                >
                  {new Date(event.eventDate).toLocaleDateString(undefined,{
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                  <br/>
                  {new Date(event.eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                </Text>
              </Box>
              <Box
                sx={{
                  mt: [3],
                  textAlign:["left","left","center","center"],
                  width: "100%",
                }}
              >
                <Heading
                  sx={{
                    color: textColor,
                    fontSize: [3, 5],
                  }}
                >
                  {event.title}
                </Heading>
              </Box>
              <Box
                sx={{
                  textAlign: ["left","left","center","center"],
                  mt: [3],
                  width: "100%",
                }}
              >
                <OutboundLink
                  sx={{
                    color: textColor,
                    fontSize: [2, 2],
                    textDecoration: "none",
                  }}
                  href={event.eventLocation.url}
                >
                  {event.eventLocation.anchor}
                </OutboundLink>
              </Box>
            </Box>
          </Box>
        </InboundLink>
      </Container>
    </Box>
  )
}



export default HighlightedEvent
