import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading } from "theme-ui"
import Breadcrumbs from "../../../components/Breadcrumbs"

const PageHero = ({ page, image }) => (
  <Box sx={{ position: "relative", mb: image && 4 }}>
    {image && (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          ".gatsby-image-wrapper": { width: "100%" },
          overflow: "hidden",
        }}
      >
        {image.gatsbyImageData && <GatsbyImage image={image.gatsbyImageData} />}
      </Box>
    )}
  </Box>
)

export default PageHero
